import { paragonReplyContext } from "@amzn/paragon-context/lib/ParagonReplyContext";

export function installParagonWidgetReplyContextProvider(el: HTMLElement) {
  const provider = paragonReplyContext.createProvider(el);

  provider.value = {
    spotAsinToolURL: "/tools/tools#/asin_troubleshooting/listings_troubleshooter;asin=asinPlaceholder",
    spotOrderToolURL: "/tools/tools#/order_information;orderId=orderIdPlaceholder",
    spotFbaPurchaseOrderToolURL: "/hz/cmt/ilac/view-ilac-report?shipmentId=shipmentIdPlaceholder",
  };
}
