import { KatAlert, KatBox } from "@amzn/katal-react";
import React, { FunctionComponent, useEffect } from "react";
import $style from './common-component.module.scss';
import { logClickStreamData } from 'src/common/utils';
import { AnalyticsFunctions } from "@amzn/paragon-context/lib/ParagonAnalyticsContext/models/AnalyticsFunctions";

type Props = {
    command: string;
    caseId: string | undefined;
    clickStream: AnalyticsFunctions;
}

export const FinalBlurb: FunctionComponent<Props> = ({ command, caseId, clickStream }) => {

    useEffect(() => {

        if (command === "END") {
            logClickStreamData(clickStream, "click", "action-plan-widget-SOP-End", {
                caseId: caseId,
                command: command,
            });
        }

    }, []);

    return (
        <div>
            <KatBox variant="white" className={$style.mainBox}>
                {command != "END" ?
                    <KatAlert variant='info'
                        persistent
                        header='SOP tasks unavailable'
                        description='Sorry, we cannot generate steps for the selected SOP at this time. Please follow standard procedures to address the case and try again later.'
                    /> :
                    <div>
                        <h6>Final task completed!</h6>
                        <p>You've successfully selected one of the recommended SOPs, executed necessary tasks, and chosen appropriate outcomes.</p>
                        <label>Now, please proceed with the final action to close this case:</label>
                        <li>Send an email to the Seller</li>
                        <li>Resolve the case</li>
                        <li>Transfer to another team (if needed)</li>
                    </div>
                }
            </KatBox>
        </div>
    );
};