import { paragonCoreDataContext } from "@amzn/paragon-context/lib/ParagonCoreDataContext";
import { TestConstants } from "./testContants";

export function installParagonCoreDataContextProvider(el: HTMLElement) {
    
    const provider = paragonCoreDataContext.createProvider(el);

    provider.value = {
        isLoading: false,
        caseDetails: {
            id: TestConstants.caseId,
            queue: 'US_BRI_Test_Queue',
            amazonGenerated: false,
            category: '',
            creationDate: Date.now(),
            doesCaseHaveTranslations: false,
            merchantId: '123',
            owner: '',
            primaryEmail: '',
            reason: '',
            severity: '',
            status: '',
            subject: '',
            tenantId: 1,
            slaExpirationDate: 1678063234000,
        },
        tenantFeatures: {},
        urls: {},
        userDetails: {
            agentLogin: 'test-user',
            email: 'test-user@amazon.com',
            languageCode: 'en_US',
            tenantId: 1,
            tenantIdList: [],
        },
    };
}
