import { KatBox, KatRadiobutton, KatSpinner, KatTableHead, KatTile, KatTileContainer, KatTableRow, KatTableBody, KatTable, KatTableCell, KatBadge, KatIcon, KatLink } from "@amzn/katal-react";
import React, { FunctionComponent, useState, useEffect } from "react";
import $style from './activity-list.module.scss';
import { getParagonDomain } from 'src/common/utils';

type Props = {
    actions: any;
    loading: boolean;
    onOutcomeSelection: (data: string) => void;
}

const SOPItems = ({ items }: any) => {
    if (!(items?.[0]?.children)) {
        items[0].children = items;
    }
    return items?.length && items.map((item) => renderItem(item))
}

const renderTable = (tableData) => {
    const headerRows = tableData[0]["children"];
    const tableRows = tableData.slice(1);
    return (<KatTable>
        <KatTableHead>
            <KatTableRow>
                {headerRows.map(header => renderItem(header))}
            </KatTableRow>
        </KatTableHead>
        <KatTableBody>
            {tableRows.map(row => {
                return <KatTableRow>
                    {row.children.map(cell => renderItem(cell))}
                </KatTableRow>
            })}
        </KatTableBody>
    </KatTable>)
}

const renderItem = (item) => {
    const { type, children, value, text, sop_id, src, href, blurb_id } = item;
    const domain: string = getParagonDomain(window.location.origin);

    switch (type) {
        case 'text':
            return <span>{value}</span>;
        case 'sop_ref':
            return <a href={domain + "/hz/codex?id=" + sop_id} target="_blank">{" "}{text}{" "}</a>;
        case 'blurb_ref':
            return <a href={domain + "/hz/codex?id=" + blurb_id} target="_blank">{" "}{text}{" "}</a>;
        case 'list':
            return (
                <ol>
                    {
                        children?.map((child) => (
                            renderItem(child)
                        ))
                    }
                </ol>
            )
        case 'list_item':
            return (
                <li>
                    {
                        children.length && children?.map((child) => renderItem(child))
                    }
                </li>
            )
        case 'image':
            return (
                <KatBox className={$style.renderImage}>
                    <img src={src} alt={value} />
                </KatBox>
            )
        case 'link':
            return (
                <a href={href} target="_blank">{" "}{children.length && children.map((c) => c.value)}{" "}</a>
            )
        case 'table':
            return (
                <div>
                    {renderTable(children)}
                </div>
            )
        case 'table_header':
        case 'table_cell':
            return <KatTableCell>{children?.map((child) => (
                renderItem(child)
            ))}</KatTableCell>;
    }
}

export const ActivityList: FunctionComponent<Props> = ({ actions, loading, onOutcomeSelection }) => {

    const domain: string = getParagonDomain(window.location.origin);

    return (
        <div className={$style.activityBox}>
            {actions?.map((action, index) => {
                const isLastIndex = index === actions.length - 1;
                return (
                    isLastIndex && (
                        <KatBox variant="white" className={$style.mainBox}>
                            {action?.sop_metadata &&
                                <div >
                                    <KatLink href={domain + "/hz/codex?id=" + action?.sop_id}
                                        target="_blank"
                                        label={action?.sop_metadata.title + " "}
                                        className={$style.sopTitle}
                                        >
                                    </KatLink>
                                    <KatBadge label="Current SOP" type="info"></KatBadge>
                                </div>
                            }
                            <h6>{`Task${index + 1}`}</h6>
                            <label className={$style.taskLabel}>Complete the steps and select an outcome you arrived at after following the process</label>
                            <h6 className={$style.stepsList}>Steps</h6>
                            <SOPItems items={action?.sop_items} />
                            <div className={$style.taskLabel}></div>
                            <h6>Select outcome</h6>
                            <div>
                                {action?.outcome_map && Object.keys(action?.outcome_map || {}).map((outcomeMapKey) => (
                                    <KatRadiobutton
                                        value={outcomeMapKey}
                                        onChange={(e) => {
                                            onOutcomeSelection((e.target as HTMLInputElement).value);
                                        }}
                                        name="action-outcome-list"
                                    >
                                        {typeof (action?.outcome_map[outcomeMapKey]?.outcome) === "string" ?
                                            <span>{action?.outcome_map[outcomeMapKey]?.outcome}</span> :
                                            (action?.outcome_map[outcomeMapKey].outcome || [])?.map((outcomeItem) => renderItem(outcomeItem)
                                            )}
                                    </KatRadiobutton>
                                ))}
                            </div>
                        </KatBox>
                    )
                );
            })}

            {loading &&
                <KatSpinner variant="default" size="small"></KatSpinner>}
        </div>
    );
};