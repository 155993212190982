import React, { FunctionComponent, PropsWithChildren, ReactNode, useCallback } from "react";

import { installParagonCoreDataContextProvider } from "./installParagonCoreDataContextProvider";
import { installParagonWidgetDataContextProvider } from "./installParagonWidgetDataContextProvider";
import { installIssueSummaryContextProvider } from "./installIssueSummaryContextProvider";
import { installParagonWidgetReplyContextProvider } from "./installParagonReplyContextProvider";

interface Props extends PropsWithChildren {}

export const ProviderMocks: FunctionComponent<Props> = ({
    children,
}) => {
    const elRefHandler = useCallback((el: HTMLElement | null) => {
        if (!el) {
            return;
        }

        installParagonWidgetReplyContextProvider(el);
        installIssueSummaryContextProvider(el);
        installParagonWidgetDataContextProvider(el);
        installParagonCoreDataContextProvider(el);
    }, []);

    // If we are in an iframe (on Paragon), do not activate mocks
    if (window.top !== window) {
        return (
            <>
                {children}
            </>
        );
    }

    return <div ref={elRefHandler}>{children}</div>;
};
